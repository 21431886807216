import React from 'react'
import './LoadingLogo.css'

export const LoadingLogo = <div className="loadingLogo">
		<div className="loadingCenter">
			<img className="loadingBounce" style={{height: 100, width: 100}} src={`/images/${process.env.REACT_APP_LOGO_BOUNCE}}`} alt="" />
			<p className="loadingText"><b>Carregando...</b></p>
		</div>
	</div>

export default LoadingLogo
