import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/bootstrap.min.css' // Bootstrap v4.5.2
import './assets/css/index.css'
import './assets/css/root.css'
import './assets/css/likert.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles'
import * as Theme from './styles'

console.log(process.env.REACT_APP_CLIENTE)
console.log(process.env.REACT_APP_WEBSITE_NAME)

const client = process.env.REACT_APP_CLIENTE

const setTheme = () => {
  switch (client) {
    case 'SADA':
      return Theme.sada
    case 'HSPW':
      return Theme.hspw
    default:
      return Theme.hspw
  }
}

ReactDOM.render(
  <ThemeProvider theme={setTheme()}>
    <GlobalStyle />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)
serviceWorker.unregister()
